<template>
  <div>
    <Header></Header>
    <div v-if='isRight'>
        <CityAnalysis v-if='regionLevel==2'></CityAnalysis>
        <ProvinceAnalysis v-if='regionLevel==1'></ProvinceAnalysis>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import CityAnalysis from '@/views/industry/cityanalysis.vue'
import ProvinceAnalysis from '@/views/industry/provinceanalysis.vue'
import {getUserRightFun} from '@/http/basicsApi.js'
import { computed, inject, ref, watch } from 'vue'
import store from '../../store'
import { useRoute } from 'vue-router'
export default {
    components:{
        Header,
        CityAnalysis,
        ProvinceAnalysis
    },
    setup() {
        const route=useRoute();
        let isRight=ref(false);
        let regionLevel=ref(0);
        let regionList=inject('regionList');
        let regionCode=computed(()=> store.state.curRegionCode);
        //判断是否有权限访问此页面
        getUserRightFun(route.name,0).then(res=>{
            isRight.value=res;
            console.log(isRight.value);
            //选中地区信息
            if(isRight.value){
                const getRegionInfoFun=(regionCode)=>{
                    //如果地区编号为空，则取当前用户所在地区
                    //console.log(regionCode);
                    let isStop=false;
                    if(regionCode){
                        console.log(regionCode);
                        console.log(regionList.value);
                        regionList.value.forEach(element => {
                            if(element.code==regionCode)
                            {
                                regionLevel.value=1;
                                return
                            }
                            element.children.forEach(child => {
                            if(child.code==regionCode)
                            {
                                regionLevel.value=2;
                                isStop=true;
                                return
                            }
                            });
                            if(isStop){
                                return;
                            }
                        });
                    }
                }
                watch(
                    () => regionCode.value,
                    (newVal,oldVal) => {
                        //console.log("监听region到变化拉！！",newVal)
                        if(!oldVal && newVal ){
                            getRegionInfoFun(newVal)
                        }
                    },
                    {
                    immediate:true,// 这个属性是重点啦
                    //deep:true // 深度监听的参数
                    }
                );
            }
        })
        
        return{
            isRight,
            regionLevel
        }
    },
}
</script>